import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { db } from '../../firebase';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';

export const API_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  GENERATED: 'GENERATED',
  MAIL_SENT: 'MAIL_SENT',
  FAILED: 'FAILED',
} as const;
export type API_STATUS = typeof API_STATUS[keyof typeof API_STATUS];

export interface ApiHistories {
  apiHistories?: Array<ApiHistory>;
  error?: SerializedError;
}

const initialState: ApiHistories = {
  apiHistories: undefined,
  error: undefined,
};

export interface ApiHistory {
  jobId: string;
  request_timestamp: number;
  generated_timestamp?: number;
  status: API_STATUS;
  reason?: string;
  path?: string;
  send_path?: string;
  uid?: string;
  thumbnail_url?: string;
  apiType?: string;
  text?: string;
  lang?: string;
  voice?: string;
  update_timestamp?: number;
  lock: boolean;
  version: number;
}

// Get ApiHistories
export const getApiHistories = createAsyncThunk<Array<ApiHistory>, string>(
  'apiHistory/fetch',
  async (uid: string): Promise<Array<ApiHistory>> => {
    const returnArray: ApiHistory[] = [];
    if (uid) {
      const historiesRef = collection(db, 'api_histories');
      const q = query(historiesRef, where('uid', '==', uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // API Historiesデータを設定
        const data = doc.data();
        const jobId = data?.jobId;
        const requestTimestamp = data?.request_timestamp;
        const generatedTimestamp = data?.generated_timestamp;
        const status = data?.status;
        const reason = data?.reason;
        const path = data?.path;
        const sendPath = data?.send_path;
        const uid = data?.uid;
        const thumbnailUrl = data?.thumbnailUrl;
        const apiType = data?.apiType;
        const text = data?.text;
        const lang = data?.lang;
        const voice = data?.voice;
        const updateTimestamp = data?.update_timestamp;
        const lock = data?.lock;
        const version = data?.version;
        returnArray.push({
          jobId: jobId,
          request_timestamp: requestTimestamp,
          generated_timestamp: generatedTimestamp,
          status: status,
          reason: reason,
          path: path,
          send_path: sendPath,
          uid: uid,
          thumbnail_url: thumbnailUrl,
          apiType: apiType,
          text: text,
          lang: lang,
          voice: voice,
          update_timestamp: updateTimestamp,
          lock: lock,
          version: version,
        } as ApiHistory);
      });
      returnArray.sort((a, b) => {
        return (a.request_timestamp ? a.request_timestamp : 0) >
          (b.request_timestamp ? b.request_timestamp : 0)
          ? -1
          : 1;
      });
      return returnArray;
    } else {
      // error
      throw new Error('apiHistories data fetch error');
    }
  }
);

export const createApiHistory = (apiHistory: ApiHistory): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const createDoc = doc(db, 'api_histories', apiHistory.jobId);
    setDoc(createDoc, apiHistory)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const apiHistoriesSlice = createSlice({
  name: 'apiHistories',
  initialState,
  reducers: {
    removeError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApiHistories.fulfilled, (state, action) => {
      state.apiHistories = [];
      action.payload.forEach((element) => {
        if (state.apiHistories) {
          state.apiHistories.push(element);
        }
      });
      state.error = undefined;
    });
    builder.addCase(getApiHistories.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { removeError } = apiHistoriesSlice.actions;
