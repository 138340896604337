import { FC } from 'react';

const GTM_TAG = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KKGFMJ4');
`;

const GTM_TAG_NOSCRIPT = `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKGFMJ4"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

export const GTM: FC = () => (
  <>
    <script dangerouslySetInnerHTML={{ __html: GTM_TAG }} />
    <noscript dangerouslySetInnerHTML={{ __html: GTM_TAG_NOSCRIPT }} />
  </>
);
