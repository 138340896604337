import { createSelector } from 'reselect';
import { RootState } from '../store';

// apiHistories情報を取得するSelector
export const apiHistoriesSelector = (state: RootState) => state.apiHistories;
/**
 * apiHistories を取得する
 * @returns apiHistories
 */
export const apiHistoriesAllSelector = createSelector(apiHistoriesSelector, (apiHistories) => {
  return apiHistories.apiHistories;
});
/**
 * error を取得する
 * @returns error
 */
export const errorSelector = createSelector(apiHistoriesSelector, (apiHistories) => {
  return apiHistories.error;
});
