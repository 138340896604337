import React, { FC } from 'react';
const MenuConfig: FC = () => {
  return (
    <div className="menu-contents menu-contents-config wf-active delay">
      <p className="menu-contents__head">設定</p>
      <a
        className="aidemo-item__btn config-btn"
        href="https://forms.gle/2bfVkJhH2XPuukSk8"
        target="_blank"
        rel="noreferrer"
      >
        アカウント情報の変更
      </a>
      <a
        className="aidemo-item__btn config-btn config-btn-white"
        href="https://forms.gle/XCDLymJSbGsnKTry9"
        target="_blank"
        rel="noreferrer"
      >
        退会
      </a>
    </div>
  );
};
export default MenuConfig;
