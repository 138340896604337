import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '../util/path';
import { userIdSelector } from '../state/selectors/userinfo';
import { useSelector } from '../state/store';

const Footer: FC = () => {
  const userId = useSelector(userIdSelector);
  const [logoPath, setlogoPath] = useState(PATH.TOP);

  useEffect(() => {
    if (userId) {
      setlogoPath(PATH.DEMO);
    } else {
      setlogoPath(PATH.TOP);
    }
  }, [userId]);

  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-inner__shelf">
          <Link className="logo" to={logoPath}>
            <img
              className="logo__img"
              src={process.env.PUBLIC_URL + '/img/logo.png'}
              alt="DATAGRID"
            />
            <span className="logo__text">AI PLATFORM</span>
          </Link>
          <div className="login-btn">
            <a
              className="login-btn__link"
              href="https://datagrid.co.jp/"
              target="_blank"
              rel="noreferrer"
            />
            <svg
              className="frame-border"
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="60"
              viewBox="0 0 160 60"
            >
              <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#fff" strokeWidth="1">
                <rect width="160" height="60" rx="30" stroke="none" />
                <rect x="0.5" y="0.5" width="159" height="59" rx="29.5" fill="none" />
              </g>
            </svg>
            <svg
              className="frame-border-gray"
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="60"
              viewBox="0 0 160 60"
            >
              <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#fff" strokeWidth="1">
                <rect width="160" height="60" rx="30" stroke="none" />
                <rect x="0.5" y="0.5" width="159" height="59" rx="29.5" fill="none" />
              </g>
            </svg>
            <span className="login-btn__text">
              運営会社
              <img src={process.env.PUBLIC_URL + '/img/icon-arrow.png'} alt="" />
            </span>
          </div>
        </div>
        <a
          className="page-top"
          href="#"
          onClick={() => setTimeout(() => history.replaceState('', '', location.pathname), 0)}
        >
          PAGE TOP
        </a>
        <small className="copy-right">© DATAGRID Inc. All right reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
