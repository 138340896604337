import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { getUserinfo } from './state/slices/userinfo';
import { useDispatch } from 'react-redux';
import {closeModal} from "./util/utils";

const ScrollToTop = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userinfo = useCallback(
    (user) => {
      dispatch(getUserinfo(user));
    },
    [dispatch]
  );
  useEffect(() => {
    let unmounted = false;
    onAuthStateChanged(auth, async (user) => {
      if (unmounted) {
        return;
      }
      try {
        await user?.reload();
      } catch (e) {
        // modal が開いたままだと閉じられなくなるため
        closeModal();
        userinfo(user);
      }
    });
    // hash へのリンクの場合はスクロール位置を調整しない
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
    // cleanup
    return () => {
      unmounted = true;
    };
  }, [location, userinfo]);

  return null;
};

export default ScrollToTop;
