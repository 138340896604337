export const fadein = (scrollTop: number, width: number, height: number) => {
  const fadeinList = document.getElementsByClassName('fadein');
  const fadein2List = document.getElementsByClassName('fadein2');
  const fadein3List = document.getElementsByClassName('fadein3');
  const fadein4List = document.getElementsByClassName('fadein4');

  if (width >= 768) {
    Array.from(fadeinList).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 150) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
    Array.from(fadein2List).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 250) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
    Array.from(fadein3List).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 300) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
    Array.from(fadein4List).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 350) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
  } else {
    Array.from(fadeinList).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 20) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
    Array.from(fadein2List).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 20) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
    Array.from(fadein3List).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 20) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
    Array.from(fadein4List).forEach((e) => {
      const elm = e as HTMLElement;
      if (scrollTop > elm.offsetTop - height + 150) {
        elm.style.opacity = '1';
        elm.style.transform = 'translateY(0)';
      }
    });
  }
};
