import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '../util/path';
import { userIdSelector } from '../state/selectors/userinfo';
import { useSelector } from '../state/store';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { createHistory, HISTORY_ACTION } from '../state/slices/histories';

const Header: FC<{ withLogin: boolean; num?: number }> = (props) => {
  const userId = useSelector(userIdSelector);
  const [logoPath, setlogoPath] = useState(PATH.TOP);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      setlogoPath(PATH.DEMO);
    } else {
      setlogoPath(PATH.TOP);
    }
  }, [userId]);

  const onClickLogin = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          await user?.reload();
          await createHistory(user.uid, HISTORY_ACTION.LOGIN);
        } catch (e) {
          console.log(e);
        }
        navigate(PATH.DEMO);
      } else {
        navigate(PATH.LOGIN, { state: { prevPathname: location.pathname } });
      }
    });
  };

  const login = props.withLogin ? (
    <div className="login-btn">
      <div className="login-btn__link login-btn-cursor" onClick={() => onClickLogin()} />
      <svg
        className="frame-border"
        xmlns="http://www.w3.org/2000/svg"
        width="140"
        height="60"
        viewBox="0 0 140 60"
      >
        {props.num === 1 ? (
          <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#fff" strokeWidth="1">
            <rect width="140" height="60" rx="30" stroke="none" />
            <rect x="0.5" y="0.5" width="139" height="59" rx="29.5" fill="none" />
          </g>
        ) : (
          <g id="長方形_2" data-name="長方形 2" fill="none" stroke="#fff" strokeWidth="1">
            <rect width="140" height="60" rx="25" stroke="none" />
            <rect x="0.5" y="0.5" width="139" height="49" rx="24.5" fill="none" />
          </g>
        )}
      </svg>
      <svg
        className="frame-border-gray"
        xmlns="http://www.w3.org/2000/svg"
        width="140"
        height="60"
        viewBox="0 0 140 60"
      >
        {props.num === 1 ? (
          <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#fff" strokeWidth="1">
            <rect width="140" height="60" rx="30" stroke="none" />
            <rect x="0.5" y="0.5" width="139" height="59" rx="29.5" fill="none" />
          </g>
        ) : (
          <g id="長方形_2" data-name="長方形 2" fill="none" stroke="#fff" strokeWidth="1">
            <rect width="140" height="60" rx="25" stroke="none" />
            <rect x="0.5" y="0.5" width="139" height="49" rx="24.5" fill="none" />
          </g>
        )}
      </svg>
      <span className="login-btn__text">LOGIN</span>
    </div>
  ) : null;
  return (
    <header className="header">
      <Link className="logo" to={logoPath}>
        <img className="logo__img" src={process.env.PUBLIC_URL + '/img/logo.png'} alt="DATAGRID" />
        <span className="logo__text">AI PLATFORM</span>
      </Link>
      {login}
      {props.children}
    </header>
  );
};

export default Header;
