import { useState, useEffect } from 'react';

export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);

  const getScrollTop = () => {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
  };

  useEffect(() => {
    const onScroll = () => {
      setScrollTop(getScrollTop());
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrollTop;
};
