import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../util/path';

const Redirect: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATH.PAGE404);
  }, [navigate]);

  return (
    <div>
      <h2>Redirect...</h2>
    </div>
  );
};
export default Redirect;
