import React, { FC, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../../../util/path';
import { Helmet } from 'react-helmet-async';
import Header from '../../../component/Header';
import Footer from '../../../component/Footer';
import { fadein } from '../../../util/fadein';
import { useScrollTop } from '../../../hook/useScrollTop';
import { useWindowDimensions } from '../../../hook/useWindowDimensions';
import { useSelector } from '../../../state/store';
import { apiHistoriesAllSelector } from '../../../state/selectors/apiHistories';
import { errorSelector, userIdSelector } from '../../../state/selectors/userinfo';
import { ApiHistory } from '../../../state/slices/apiHistories';
import { createHistory, HISTORY_ACTION } from '../../../state/slices/histories';
import { GTM } from '../../../component/GTM';

const Download: FC = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const scrollTop = useScrollTop();
  const { width, height } = useWindowDimensions();
  const apiHistories = useSelector(apiHistoriesAllSelector);
  const error = useSelector(errorSelector);
  const userId = useSelector(userIdSelector);

  // SP表示時も横幅をPCと同一に保つ
  const viewportMeta = document.getElementById('viewportMeta');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', 'width=1200');
  }

  useEffect(() => {
    if (error) {
      navigate(PATH.LOGIN, { state: { prevPathname: location.pathname }, replace: true });
    }
  }, [error, navigate]);
  useEffect(() => {
    fadein(scrollTop, width, height);
  }, [scrollTop, width, height]);

  useEffect(() => {
    if (apiHistories !== undefined) {
      let foundApiHistory: ApiHistory | undefined = undefined;
      for (const apiHistory of apiHistories) {
        if (uuid === apiHistory.send_path && apiHistory.path) {
          foundApiHistory = apiHistory;
        }
      }
      if (foundApiHistory && foundApiHistory.path && foundApiHistory.jobId && userId) {
        const path = foundApiHistory.path;
        const attr = { jobId: foundApiHistory.jobId };
        setTimeout(async () => {
          await createHistory(userId, HISTORY_ACTION.DL_DEMO, attr);
          window.location.href = path;
        }, 1000);
      } else {
        navigate(PATH.PAGE404, { state: { prevPathname: location.pathname } });
      }
    }
  }, [apiHistories, navigate, uuid, userId]);
  return (
    <div>
      <GTM />
      <Helmet>
        <title>生成動画ダウンロード｜株式会社データグリッド - AIプラットフォーム</title>
      </Helmet>
      <div className="under" id="error">
        <div className="back-dots">
          <Header withLogin={false} />
          <div className="error-bg" />
          <main>
            <div className="bg-rough">
              <div className="error-page">
                <h1 className="error-page__head fadein" />
                <h2 className="error-page__subhead fadein ">Please wait while redirecting...</h2>
                <p className="error-page__text fadein ">
                  動画のダウンロードが開始されるまで
                  <br />
                  しばらくお待ち下さい。
                </p>
                <Link className="login-item__btn fadein " to={PATH.TOP}>
                  TOPへもどる
                </Link>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Download;
