import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { User } from '@firebase/auth';

export interface UserinfoState {
  userId?: string;
  email?: string;
  name?: string;
  organization?: string;
  organizationUnit?: string;
  position?: string;
  error?: SerializedError;
}

const initialState: UserinfoState = {
  userId: undefined,
  email: undefined,
  name: undefined,
  organization: undefined,
  organizationUnit: undefined,
  position: undefined,
  error: undefined,
};

interface Userinfo {
  userId?: string;
  email?: string;
  name?: string;
  organization?: string;
  organizationUnit?: string;
  position?: string;
}

// Get Userinfo
export const getUserinfo = createAsyncThunk<Userinfo, User>(
  'userinfo/fetch',
  async (user: User): Promise<Userinfo> => {
    if (user) {
      // ユーザー情報を設定
      const data = (await getDoc(doc(db, 'users/' + user.uid))).data();
      const email = user?.email || '';
      const name = data?.name || '';
      const organization = data?.organization || '';
      const organizationUnit = data?.organization_unit || '';
      const position = data?.position || '';
      return {
        userId: user.uid,
        email: email,
        name: name,
        organization: organization,
        organizationUnit: organizationUnit,
        position: position,
      } as Userinfo;
    } else {
      // error
      throw new Error('userinfo data fetch error');
    }
  }
);

export const userinfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    removeError: (state) => {
      state.error = undefined;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = new Error(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserinfo.fulfilled, (state, action) => {
      state.userId = action.payload.userId;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.organization = action.payload.organization;
      state.organizationUnit = action.payload.organizationUnit;
      state.position = action.payload.position;
      state.error = undefined;
    });
    builder.addCase(getUserinfo.rejected, (state, action) => {
      state.userId = undefined;
      state.email = undefined;
      state.name = undefined;
      state.organization = undefined;
      state.organizationUnit = undefined;
      state.position = undefined;
      state.error = action.error;
    });
  },
});

export const { removeError, setError } = userinfoSlice.actions;
