import React, { FC } from 'react';
import { useSelector } from '../state/store';
import {
  emailSelector,
  nameSelector,
  organizationSelector,
  organizationUnitSelector,
  positionSelector,
} from '../state/selectors/userinfo';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { closeModal } from '../util/utils';
const MenuAccount: FC = () => {
  const email = useSelector(emailSelector);
  const name = useSelector(nameSelector);
  const organization = useSelector(organizationSelector);
  const organizationUnit = useSelector(organizationUnitSelector);
  const position = useSelector(positionSelector);

  return (
    <div className="menu-contents menu-contents-account wf-active delay">
      <p className="menu-contents__head">アカウント情報</p>
      <div className="menu-table">
        <div className="menu-table__tr">
          <div className="menu-table__th">お名前</div>
          <div className="menu-table__td">{name}</div>
        </div>
        <div className="menu-table__tr">
          <div className="menu-table__th">企業・団体名</div>
          <div className="menu-table__td">{organization}</div>
        </div>
        <div className="menu-table__tr">
          <div className="menu-table__th">部署名</div>
          <div className="menu-table__td">{organizationUnit}</div>
        </div>
        <div className="menu-table__tr">
          <div className="menu-table__th">お役職</div>
          <div className="menu-table__td">{position}</div>
        </div>
        <div className="menu-table__tr">
          <div className="menu-table__th">メールアドレス</div>
          <div className="menu-table__td">{email}</div>
        </div>
      </div>
      <a
        className="aidemo-item__btn"
        href="#"
        onClick={() => {
          signOut(auth);
          closeModal();
        }}
      >
        ログアウト
      </a>
    </div>
  );
};
export default MenuAccount;
