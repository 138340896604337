import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './style/style.css';
import './style/responsive.css';
import './style/extension.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <HelmetProvider>
        <Helmet>
          <title>My Title</title>
          <meta property="og:url" content="https://ai-platform.datagrid.co.jp" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="デジタルヒューマンAIプラットフォーム｜株式会社データグリッド"
          />
          <meta
            property="og:description"
            content="当プラットフォームでは、データグリッドの持つ最先端のデジタルヒューマンAI技術を無料で体験いただけます。顔や服装、姿勢や声も思いのまま。コンテンツ制作を変える多様な技術の商用利用のご相談も受け付けています。"
          />
          <meta property="og:site_name" content="デジタルヒューマンAIプラットフォーム" />
          <meta property="og:image" content={window.location.origin + '/img/og_img.png'} />
        </Helmet>
        <App />
      </HelmetProvider>
    </React.StrictMode>
  </Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
