import { configureStore } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';
import { userinfoSlice } from './slices/userinfo';
import { apiHistoriesSlice } from './slices/apiHistories';
import { historiesSlice } from './slices/histories';

export const store = configureStore({
  reducer: {
    userinfo: userinfoSlice.reducer,
    apiHistories: apiHistoriesSlice.reducer,
    histories: historiesSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
