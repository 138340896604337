import React, { FC, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../util/path';
import { useSelector } from '../../../state/store';
import { errorSelector, userIdSelector } from '../../../state/selectors/userinfo';
import { useScrollTop } from '../../../hook/useScrollTop';
import { useWindowDimensions } from '../../../hook/useWindowDimensions';
import { fadein } from '../../../util/fadein';
import { API_STATUS, ApiHistory, createApiHistory } from '../../../state/slices/apiHistories';
import { callLipsyncApi, playAudioWithAzureSynthesizer, Lang } from '../../../util/api';
import Menu, { ShowMenuHandle } from '../../../component/Menu';
import { openModal, closeModal } from '../../../util/utils';
import { Helmet } from 'react-helmet-async';
import { createHistory, HISTORY_ACTION } from '../../../state/slices/histories';
import Header from '../../../component/Header';
import Footer from '../../../component/Footer';
import { GTM } from '../../../component/GTM';

const DEFAULT_DIGITAL_HUMAN = 'woman_01';
export const DEFAULT_DIGITAL_HUMAN_IMG = 'url(/img/woman01@2x.png)';
export const DIGITAL_HUMANS = [
  {
    img: DEFAULT_DIGITAL_HUMAN_IMG,
    name: 'WOMAN 01',
    value: DEFAULT_DIGITAL_HUMAN,
  },
  {
    img: 'url(/img/woman02@2x.png)',
    name: 'WOMAN 02',
    value: 'woman_02',
  },
  {
    img: 'url(/img/man01@2x.png)',
    name: 'MAN 01',
    value: 'man_01',
  },
  {
    img: 'url(/img/man02@2x.png)',
    name: 'MAN 02',
    value: 'man_02',
  },
];

const LipsyncAi: FC = () => {
  const MAX_TEXT_LENGTH = 200;
  const UNSELECTED_VALUE = 'unselected';
  const DEFAULT_YOUTUBE_URL =
    'https://www.youtube-nocookie.com/embed/FOpCoiNhTK8?autoplay=1&mute=1&playsinline=1&loop=1&playlist=FOpCoiNhTK8';

  const navigate = useNavigate();
  const userId = useSelector(userIdSelector);
  const error = useSelector(errorSelector);
  const [lipSyncText, setLipSyncText] = useState<string>('');
  const [lang, setLang] = useState<string>(UNSELECTED_VALUE);
  const [youtubeSrc, setYoutubeSrc] = useState<string>(DEFAULT_YOUTUBE_URL);
  const [digitalHuman, setDigitalHuman] = useState<string>(DEFAULT_DIGITAL_HUMAN);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [speaking, setSpeaking] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const MenuRef = useRef<ShowMenuHandle>(null);

  // SP表示時も横幅をPCと同一に保つ
  const viewportMeta = document.getElementById('viewportMeta');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', 'width=1200');
  }

  const onClickMenu = (type: string) => {
    MenuRef.current?.showMenu(type);
  };

  const humanList = [];

  const selectHuman = (human: string) => {
    setDigitalHuman(human);
  };
  const selectedHumanImage = () => {
    for (const human of DIGITAL_HUMANS) {
      if (human.value === digitalHuman) {
        return { backgroundImage: human.img };
      }
    }
  };

  for (const human of DIGITAL_HUMANS) {
    const selected =
      human.value === digitalHuman ? <div className="select-item-filter" /> : <div />;
    humanList.push(
      <li className="select-item" onClick={() => selectHuman(human.value)} key={human.value}>
        <div className="select-item__fuman" style={{ backgroundImage: human.img }}>
          {selected}
        </div>
        <p className="select-item__name">{human.name}</p>
      </li>
    );
  }

  useEffect(() => {
    if (error) {
      navigate(PATH.LOGIN, { state: { prevPathname: location.pathname }, replace: true });
    }
  }, [error, navigate]);

  /**
   * テキスト入力イベント
   * @param e
   */
  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLipSyncText(e.target.value);
    // バリデーションチェック
    const calcTrimText = e.target.value.replace(/\r?\n/g, '').length;
    if (calcTrimText > MAX_TEXT_LENGTH || calcTrimText === 0 || lang === UNSELECTED_VALUE) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  /**
   * 言語選択変更
   * @param e
   */
  const handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLang(e.target.value);
    // バリデーションチェック
    if (calcTrimText() > MAX_TEXT_LENGTH || calcTrimText() === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  /**
   * 音声読み上げボタン押下イベント
   */
  const onClickTextToSpeech = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && !speaking) {
      setSpeaking(true);
      e.preventDefault();
      try {
        playAudioWithAzureSynthesizer(lang as Lang, digitalHuman, trimText(), setSpeaking);
      } catch (e) {
        alert(e);
      }
      loggingPlayTest();
    }
  };
  /**
   * LipsyncAPI 呼び出し
   */
  const onClickLipsyncAPI = () => {
    if (!disabled && !generating) {
      setGenerating(true);
      openModal();
      callLipsyncApi(lang as Lang, digitalHuman, trimText())
        .then((jobId) => {
          console.log(jobId);
          setShowModal('modal-02');
          if (userId) {
            const data: ApiHistory = {
              jobId: jobId,
              request_timestamp: Math.floor(Date.now() / 1000),
              status: API_STATUS.IN_PROGRESS,
              uid: userId,
              text: lipSyncText,
              lang: lang,
              voice: digitalHuman,
              apiType: 'LipsyncAPI',
              lock: false,
              version: 0,
            };
            createApiHistory(data);
          }
          loggingRequestSample(jobId);
          setGenerating(false);
        })
        .catch((e) => {
          alert(e);
          setGenerating(false);
        });
    }
  };

  // fadein
  const scrollTop = useScrollTop();
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    fadein(scrollTop, width, height);
  }, [scrollTop, width, height]);
  const [showModal, setShowModal] = useState<string | undefined>(undefined);
  const modalClass = (modalId: string) => {
    return showModal === modalId ? 'modal-wrapper modal-show' : 'modal-wrapper modal-hide';
  };
  const stopPlayVideo = () => {
    closeModal();
    setYoutubeSrc('');
    setShowModal(undefined);
  };
  useEffect(() => {
    if (youtubeSrc === '') {
      setYoutubeSrc(DEFAULT_YOUTUBE_URL);
    }
  }, [youtubeSrc]);

  const setTextOverClass = () => {
    return calcTrimText() > MAX_TEXT_LENGTH ? 'character over' : 'character';
  };
  const overTextCount = () => {
    return (MAX_TEXT_LENGTH - calcTrimText()).toString();
  };
  const calcOffset = () => {
    return calcTrimText() > MAX_TEXT_LENGTH
      ? 0
      : 76 - Math.floor((calcTrimText() / MAX_TEXT_LENGTH) * 76);
  };
  const calcTrimText = () => {
    return trimText().length;
  };
  const trimText = () => {
    return lipSyncText.replace(/\r?\n/g, '');
  };
  // ログ保存用
  const loggingPlaySample = async () => {
    if (userId) {
      const attr = { url: youtubeSrc };
      await createHistory(userId, HISTORY_ACTION.PLAY_SAMPLE, attr);
    }
  };
  const loggingPlayTest = async () => {
    if (userId) {
      const attr = {
        text: lipSyncText,
        lang: lang,
        digital_human: digitalHuman,
        demo_type: 'LipsyncAI',
      };
      await createHistory(userId, HISTORY_ACTION.PLAY_TEXT, attr);
    }
  };
  const loggingRequestSample = async (jobId: string) => {
    if (userId) {
      const attr = {
        text: lipSyncText,
        lang: lang,
        digital_human: digitalHuman,
        demo_type: 'LipsyncAPI',
        jobId: jobId,
      };
      await createHistory(userId, HISTORY_ACTION.REQUEST_DEMO, attr);
    }
  };
  const loggingAPIRequest = async () => {
    if (userId) {
      await createHistory(userId, HISTORY_ACTION.REQUEST_APIKEY);
    }
  };

  return (
    <div>
      <GTM />
      <Helmet>
        <title>リップシンクAI｜株式会社データグリッド - AIプラットフォーム</title>
        <meta
          name="description"
          content="リップシンクAIでは、入力されたテキストに応じて、音声を生成し、さらにその音声に合わせてデジタルヒューマンが話している動画を自動的に生成することができます。"
        />
      </Helmet>
      <div className="under" id="aidemo">
        <div className="back-dots">
          <Header withLogin={false}>
            <div className="icon-shelf">
              <div className="icon-shelf__box" onClick={() => onClickMenu('log')}>
                {' '}
                <span className="icon-shelf__text">履歴</span>
                <img src={process.env.PUBLIC_URL + '/img/icon-history_white.png'} alt="" />
              </div>
              <div className="icon-shelf__box" onClick={() => onClickMenu('account')}>
                {' '}
                <span className="icon-shelf__text">マイページ</span>
                <img src={process.env.PUBLIC_URL + '/img/icon-human_white.png'} alt="" />
              </div>
              <div className="icon-shelf__box" onClick={() => onClickMenu('config')}>
                {' '}
                <span className="icon-shelf__text">設定</span>
                <img src={process.env.PUBLIC_URL + '/img/icon-gear_white.png'} alt="" />
              </div>
            </div>
          </Header>
          <Menu ref={MenuRef} />
          <main>
            <div className="bg-rough">
              <div className="aidemo-page">
                <div className="oversized-head">
                  <p className="oversized-number fadein">01</p>
                  <p className="oversized-subtitle fadein">AI DEMO</p>
                  <h1 className="oversized-title fadein">
                    リップシンクA
                    <span className="ai-space" />I
                  </h1>
                </div>
                <div className="under-line fadein"></div>
                <div className="report">
                  <p className="report__text fadein">
                    リップシンクAIでは、入力されたテキストに応じて、音声を生成し、さらにその音声に合わせてデジタルヒューマンが話している動画を自動的に生成することができます。この技術を応用することで、eラーニング動画や販促動画、ニュース動画といった人物を活用した動画の制作プロセスを大きく効率化し、低コスト化と制作リードタイムの短縮により動画コンテンツの活用を促進します。
                  </p>
                  <a
                    className="report-modal fadein"
                    onClick={() => {
                      openModal();
                      loggingPlaySample();
                      setShowModal('modal-01');
                    }}
                  >
                    <div
                      className="report-modal__pic"
                      style={{ backgroundImage: 'url(/img/pic-demo01-L.jpg)' }}
                    ></div>
                    <div className="play-btn">
                      <span>
                        PLAY
                        <img src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'} alt="" />
                      </span>
                    </div>
                  </a>
                </div>
                <p className="aidemo-subhead-sup fadein">AI デモンストレーション</p>
                <h2 className="aidemo-subhead fadein">無料のAIビデオを作成</h2>
                <div className="under-line fadein"></div>
                <div className="report report-pb0">
                  <p className="report__text fadein">
                    デジタルヒューマンと、任意のテキストを組み合わせて無料のAIビデオを生成することができます。以下よりデジタルヒューマンの画像を選択し、発話させたいテキストを入力。テキストに応じた言語を選択して、動画生成ボタンをクリックしてください。
                  </p>
                  <h3 className="report__head fadein">アウトプット形式について</h3>
                  <p className="report__text fadein">
                    アウトプット形式はmp4となります。デモデータのご利用は、閲覧のみとさせていただきますので、商用利用のご希望についてはお気軽にお問い合わせください。
                  </p>
                  <div className="choice-shelf">
                    <div className="choosing fadein" style={selectedHumanImage()}>
                      <span className="choosing__text">選択中のデジタルヒューマン</span>
                    </div>
                    <div className="select-box fadein2">
                      <h4 className="select-title">デジタルヒューマンを選択してください。</h4>
                      <ul className="select-box__scroll overflow-x-hidden">{humanList}</ul>
                    </div>
                  </div>
                  <div className="input-box fadein">
                    <p className="input-box__text">
                      スクリプトを200文字以内で入力し、言語を選択してください。{' '}
                      <span>※ 日本語・英語・中国語のいずれかを単一言語で入力してください。</span>
                    </p>
                    <div className="input-field">
                      <textarea
                        placeholder="はじめまして、私はデータグリッドのAIアンバサダーです。"
                        defaultValue={lipSyncText}
                        onChange={handleTextChange}
                      />
                      <div className="under-line" />
                      <div className="language-choice">
                        <div className="select-shelf">
                          <div className="select-flame">
                            <select name="lang" value={lang} onChange={handleLangChange}>
                              <option value={UNSELECTED_VALUE} disabled style={{ display: 'none' }}>
                                入力した言語を選択
                              </option>
                              <option value="ja-JP">日本語</option>
                              <option value="en-US">英語</option>
                              <option value="zh-CN">中国語</option>
                            </select>
                          </div>
                          {/* className="over"付与で赤くなる */}
                          <div className={setTextOverClass()}>
                            <svg
                              className="character-circle"
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                            >
                              <g
                                id="楕円形_1"
                                data-name="楕円形 1"
                                transform="translate(0 28) rotate(-90)"
                                fill="none"
                                stroke=""
                                strokeWidth="4"
                              >
                                <circle cx="14" cy="14" r="14" stroke="none" />
                                <circle
                                  cx="14"
                                  cy="14"
                                  r="12"
                                  fill="none"
                                  strokeDashoffset={calcOffset()}
                                />
                              </g>
                            </svg>
                            <span>{overTextCount()}</span>
                          </div>
                        </div>
                        <div
                          className={`read-about ${
                            (disabled || speaking) && 'read-about-disabled'
                          }`}
                          onClick={(e) => onClickTextToSpeech(e)}
                        >
                          <div className="read-about__inner">
                            <img src={process.env.PUBLIC_URL + '/img/icon-read.png'} alt="" />
                            <img
                              className="read-about__hover-icon"
                              src={process.env.PUBLIC_URL + '/img/icon-read_hover.png'}
                              alt=""
                            />
                            <span>{speaking ? '読み上げ中' : '読み上げる'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className={`generation-btn fadein ${
                      (disabled || generating) && 'generation-btn-disabled'
                    }`}
                    onClick={() => onClickLipsyncAPI()}
                  >
                    上記設定で動画を生成する
                  </a>
                  <div className="about-box">
                    <h3 className="abou-box__title fadein">商用利用について</h3>
                    <div className="under-line fadein"></div>
                    <p className="report__text fadein">
                      お試しいただいたデジタルヒューマン技術の商用利用について、ご興味・ご相談のある方はお気軽にお問い合わせください。APIとしてのご提供やサービスの共同開発など、ご要望に応じたご提案をさせていただきます。
                    </p>
                    <h3 className="abou-box__title abou-box__title-second fadein">PROCESS</h3>
                    <ul className="step-box">
                      <li className="step-shelf fadein">
                        <p className="step-number">STEP1</p>
                        <p className="step-text position-static">AI商用利用についてお問い合わせ</p>
                      </li>
                      <li className="step-shelf fadein">
                        <p className="step-number">STEP2</p>
                        <p className="step-text position-static">
                          データグリッド営業担当者とのお打ち合わせ
                        </p>
                      </li>
                      <li className="step-shelf fadein">
                        <p className="step-number">STEP3</p>
                        <p className="step-text">プロジェクトの進め方を策定</p>
                      </li>
                      <li className="step-shelf fadein">
                        <p className="step-number">STEP4</p>
                        <p className="step-text">AI技術のAPIとしてのご提供や、サービスの共同開発</p>
                      </li>
                    </ul>
                    <div className="contact-box fadein">
                      <h3 className="contact-head fadein">AIの商用利用についてお問い合わせ</h3>
                      <p className="contact-text fadein">
                        APIとしてのご提供やサービスの共同開発などをご検討の方は、こちらからお問い合わせください。
                      </p>
                      <a
                        className="contact-btn fadein"
                        href="https://forms.gle/XE88HLpnL2DTvH3Y9"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => loggingAPIRequest()}
                      >
                        お問い合わせ
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* モーダル枠 */}
            <div className={modalClass('modal-01')} id="modal-01">
              <a className="modal-overlay" onClick={() => stopPlayVideo()} />
              <div className="modal-window">
                <div className="modal-content movie">
                  <iframe
                    id="youtube-player"
                    width="560"
                    height="315"
                    src={youtubeSrc}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <a className="close-btn modal-close" onClick={() => stopPlayVideo()}>
                  <div className="close-btn__border"> </div>
                  <div className="close-btn__cross"></div>
                  <svg
                    className="close-btn__circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#000" strokeWidth="1">
                      <rect width="30" height="30" rx="15" stroke="none" />
                      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div className={modalClass('modal-02')} id="modal-02">
              <a
                className="modal-overlay"
                onClick={() => {
                  setShowModal(undefined);
                  closeModal();
                }}
              />
              <div className="modal-window modal-window-text">
                <div className="modal-content">
                  <p className="bold-text">
                    AIによる動画生成を開始しました。
                    <br />
                    AI生成動画の準備が完了しましたら、
                    <br />
                    ダウンロードURLをメールにて送付いたします。
                  </p>
                  <p className="normal-text">
                    動画生成時間はテキストの長さによって異なります。
                    <br />
                    1時間を経過してもメールが届かない場合には、
                    <br />
                    <a href="mailto:support@datagrid.co.jp">support@datagrid.co.jp</a>
                    までご連絡ください。
                  </p>
                  <a
                    className="aidemo-item__btn aidemo-item__btn-modal"
                    onClick={() => {
                      setShowModal(undefined);
                      closeModal();
                    }}
                  >
                    閉じる
                  </a>
                </div>
                <a
                  className="close-btn modal-close"
                  onClick={() => {
                    setShowModal(undefined);
                    closeModal();
                  }}
                >
                  <div className="close-btn__border"> </div>
                  <div className="close-btn__cross"></div>
                  <svg
                    className="close-btn__circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#000" strokeWidth="1">
                      <rect width="30" height="30" rx="15" stroke="none" />
                      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LipsyncAi;
