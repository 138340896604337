import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import MenuAccount from './MenuAccount';
import MenuLog from './MenuLog';
import MenuConfig from './MenuConfig';
import { closeModal, openModal } from '../util/utils';
import { useDispatch } from 'react-redux';
import { getApiHistories } from '../state/slices/apiHistories';
import { useSelector } from '../state/store';
import { userIdSelector } from '../state/selectors/userinfo';
export interface ShowMenuHandle {
  showMenu(type: string): void;
}
const Menu = forwardRef<ShowMenuHandle>((props, ref) => {
  const userId = useSelector(userIdSelector);
  const dispatch = useDispatch();
  const apiHistory = useCallback(
    (user) => {
      dispatch(getApiHistories(user));
    },
    [dispatch]
  );
  useImperativeHandle(ref, () => ({
    showMenu(type) {
      openModal();
      setShowMenu('open');
      setShowSlide('open');
      setType(type);
      apiHistory(userId);
    },
  }));
  const [showMenu, setShowMenu] = useState<string>('');
  const [showSlide, setShowSlide] = useState<string>('');
  const [type, setType] = useState<string>('');
  const onClose = () => {
    closeModal();
    setTimeout(() => setShowMenu('close'), 500);
    setShowSlide('close');
  };
  const getMenuClass = () => {
    if (showMenu === 'open') {
      return 'menu';
    } else if (showMenu === 'close') {
      return 'hidden';
    } else {
      return 'hidden';
    }
  };
  const getSlideClass = () => {
    if (showSlide === 'open') {
      return 'slide-wrap slide-in-right';
    } else if (showSlide === 'close') {
      return 'slide-wrap slide-out-right';
    } else {
      return 'hidden';
    }
  };
  const menuContents =
    type === 'account' ? <MenuAccount /> : type === 'config' ? <MenuConfig /> : <MenuLog />;
  const setClassName = (menuType: string) => {
    return menuType === type ? 'icon-shelf__box point' : 'icon-shelf__box';
  };
  const changeType = (selectType: string) => {
    if (selectType === 'log') {
      apiHistory(userId);
    }
    setType(selectType);
    setShowMenu('open');
  };

  return (
    <div className={getMenuClass()}>
      <div className={getSlideClass()} id="menu">
        <div className="menu-inner">
          {/* closeボタン */}
          <div className="close-btn" onClick={() => onClose()}>
            <div className="close-btn__border"></div>
            <div className="close-btn__cross"></div>
            <svg
              className="close-btn__circle"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#000" strokeWidth="1">
                <rect width="30" height="30" rx="15" stroke="none" />
                <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
              </g>
            </svg>
          </div>
          <div className="icon-shelf wf-active delay">
            <div className={setClassName('log')} onClick={() => changeType('log')}>
              <span className="icon-shelf__text">履歴</span>
              <img src={process.env.PUBLIC_URL + '/img/icon-history_black.png'} alt="" />
            </div>
            <div className={setClassName('account')} onClick={() => changeType('account')}>
              <span className="icon-shelf__text">マイページ</span>
              <img src={process.env.PUBLIC_URL + '/img/icon-human_black.png'} alt="" />
            </div>
            <div className={setClassName('config')} onClick={() => changeType('config')}>
              <span className="icon-shelf__text">設定</span>
              <img src={process.env.PUBLIC_URL + '/img/icon-gear_black.png'} alt="" />
            </div>
          </div>
          {menuContents}
        </div>
      </div>
    </div>
  );
});
Menu.displayName = 'Menu';
export default Menu;
