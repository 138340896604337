import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '../../util/path';
import { useScrollTop } from '../../hook/useScrollTop';
import { useWindowDimensions } from '../../hook/useWindowDimensions';
import { fadein } from '../../util/fadein';
import { openModal, closeModal } from '../../util/utils';
import { Helmet } from 'react-helmet-async';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { GTM } from '../../component/GTM';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { createHistory, HISTORY_ACTION } from '../../state/slices/histories';

const Top: FC = () => {
  const DEFAULT_YOUTUBE_URL =
    'https://www.youtube-nocookie.com/embed/FOpCoiNhTK8?autoplay=1&mute=1&playsinline=1&loop=1&playlist=FOpCoiNhTK8';

  const scrollTop = useScrollTop();
  const { width, height } = useWindowDimensions();
  const [youtubeSrc, setYoutubeSrc] = useState<string>(DEFAULT_YOUTUBE_URL);
  const [cta, setCta] = useState<boolean>(true);
  const navigate = useNavigate();

  // 横幅をデバイスに合わせる
  const viewportMeta = document.getElementById('viewportMeta');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0');
  }

  useEffect(() => {
    fadein(scrollTop, width, height);
  }, [scrollTop, width, height]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const modalClass = () => {
    return showModal ? 'modal-wrapper modal-show' : 'modal-wrapper modal-hide';
  };
  const stopPlayVideo = () => {
    setYoutubeSrc('');
    setShowModal(false);
    closeModal();
  };
  useEffect(() => {
    if (youtubeSrc === '') {
      setYoutubeSrc(DEFAULT_YOUTUBE_URL);
    }
  }, [youtubeSrc]);
  const toggleCta = () => {
    setCta(!cta);
  };
  const getCtaClass = () => {
    return cta ? 'cta active' : 'cta';
  };

  const onClickLogin = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          await user?.reload();
          await createHistory(user.uid, HISTORY_ACTION.LOGIN);
        } catch (e) {
          console.log(e);
        }
        navigate(PATH.DEMO);
      } else {
        navigate(PATH.LOGIN, { state: { prevPathname: location.pathname } });
      }
    });
  };

  return (
    <div>
      <GTM />
      <Helmet>
        <title>デジタルヒューマンAIプラットフォーム｜株式会社データグリッド</title>
        <meta
          name="description"
          content="当プラットフォームでは、データグリッドの持つ最先端のデジタルヒューマンAI技術を無料で体験いただけます。顔や服装、姿勢や声も思いのまま。コンテンツ制作を変える多様な技術の商用利用のご相談も受け付けています。"
        />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div id="top">
        <div className="back-dots">
          <main>
            <div className="fv-area">
              <Header withLogin={true} num={1} />
              {/* CTA */}
              <div className={getCtaClass()}>
                <div className="cta-open" onClick={() => toggleCta()}>
                  <span>無料アカウント申請</span>
                </div>
                <div className="cta-left">
                  <h2 className="cta__head">無料アカウント申請</h2>
                  <a
                    className="cta__btn"
                    href="https://forms.gle/zBsnfteKsnkdFmKY8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>アカウントを申請する</span>
                  </a>
                </div>
                <div className="cta-right">
                  <p className="cta__text">
                    当社よりアカウントを付与させていただいた後には、
                    <br />
                    すべてのAIモジュールをお試しいただけます。
                  </p>
                  <p className="cta__smalltext">
                    アカウントをお持ちの方は
                    <div className="login-btn-cursor" onClick={() => onClickLogin()}>
                      こちらから ログイン
                    </div>
                  </p>
                </div>
                {/* closeボタン */}
                <div className="close-btn" onClick={() => toggleCta()}>
                  <div className="close-btn__border"> </div>
                  <div className="close-btn__cross"></div>
                  <svg
                    className="close-btn__circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g id="長方形_1" data-name="長方形 1" fill="none" stroke="#000" strokeWidth="1">
                      <rect width="30" height="30" rx="15" stroke="none" />
                      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="fv-area__side">
                <span>SCROLL DOWN</span>
                <span>© DATAGRID Inc. All right reserved.</span>
              </div>
              <div className="fv-area__contents">
                <h1 className="fv-title">
                  最先端の
                  <br />
                  デジタルヒューマンAI技術を体験
                </h1>
                <p className="fv-text">
                  顔や服装、ポーズや声も思いのままに。
                  <br />
                  デジタルヒューマンでコンテンツ制作を変える
                  <br />
                  AIプラットフォーム
                </p>
                <p className="fv-subtext">
                  AI PLATFORM FOR EXPERIENCING
                  <br />
                  DIGITAL HUMAN AI TECHNOLOGY.
                </p>
              </div>
              <div className="fv-rotate-box">
                <ul className="fv-rotate-shelf">
                  <li className="fv-rotate-box__text">DIGITAL HUMAN</li>
                  <li className="fv-rotate-box__text"> AI PLATFORM</li>
                </ul>
              </div>
              <div className="fv-area__back fv-area__back-01"></div>
              <div className="fv-area__back fv-area__back-02"></div>
              <div className="fv-area__back fv-area__back-03"></div>
            </div>
            <section className="howto">
              <div className="top-heading">
                <h2 className="top-heading__title fadein">
                  <span>ご利用方法</span>HOW TO USE
                </h2>
                <div className="white-line fadein"></div>
              </div>
              <div className="howto-shelf">
                <div className="howto-shelf__item howto-shelf__item-01 fadein">
                  <h3 className="howto-shelf__title">
                    <span>01</span>無料アカウント申請・取得
                  </h3>
                  <img
                    className="howto-shelf__img"
                    src={process.env.PUBLIC_URL + '/img/howto_01.png'}
                    alt=""
                  />
                  <p className="howto-shelf__text">
                    当サイトからアカウントをご申請ください。当社から、アカウント情報を送付いたします。
                  </p>
                  <p className="howto-shelf__text-gray">APPLY FOR AND GET A FREE ACCOUNT</p>
                </div>
                <div className="howto-shelf__item howto-shelf__item02 fadein2">
                  <h3 className="howto-shelf__title">
                    <span>02</span>AIのテスト利用
                  </h3>
                  <img
                    className="howto-shelf__img"
                    src={process.env.PUBLIC_URL + '/img/howto_03.png'}
                    alt=""
                  />
                  <p className="howto-shelf__text">
                    データグリッドの持つ最先端のデジタルヒューマンAI技術を無料で体験いただけます。
                  </p>
                  <p className="howto-shelf__text-gray">TEST USE OF AI</p>
                </div>
                <div className="howto-shelf__item howto-shelf__item03 fadein3">
                  <h3 className="howto-shelf__title">
                    <span>03</span>商用利用申請
                  </h3>
                  <img
                    className="howto-shelf__img"
                    src={process.env.PUBLIC_URL + '/img/howto_02.png'}
                    alt=""
                  />
                  <p className="howto-shelf__text">
                    様々な領域のコンテンツ制作に活用できる、AI技術の商用利用についてお問い合わせいただけます。
                  </p>
                  <p className="howto-shelf__text-gray">APPLICATION FOR COMMERCIAL USE</p>
                </div>
              </div>
            </section>
            <section className="top-aidemo">
              <div className="top-heading">
                <h2 className="top-heading__title top-heading__title-ai fadein">
                  <span>AI デモンストレーション</span>AI DEMO
                  <p>※スマートフォン、タブレットではご利用になれません。PCでご利用ください。</p>
                </h2>
              </div>
              <div className="top-heading top-heading-none">
                <h2 className="top-heading__title top-heading__title-ai fadein">
                  <span>AI デモンストレーション</span>AI DEMO
                </h2>
                <div className="white-line fadein"></div>
                <p className="fadein">
                  ※スマートフォン、タブレットではご利用になれません。PCでご利用ください。
                </p>
              </div>
              <div className="aidemo-shelf fadein">
                <div className="aidemo-item fadein">
                  <h4 className="aidemo-item__head">
                    AI DEMO<span>01</span>
                  </h4>
                  <a
                    className="aidemo-item__thumb"
                    onClick={() => {
                      openModal();
                      setShowModal(true);
                    }}
                  >
                    <div className="play-btn">
                      <div className="play-btn__inner">
                        <img
                          className="play-btn__icon"
                          src={process.env.PUBLIC_URL + '/img/icon-play.png'}
                          alt=""
                        />
                        <span className="play-btn__text">PLAY</span>
                      </div>
                    </div>
                    <div className="aidemo-item__thumb-frame">
                      <div
                        className="aidemo-item__thumb-pic"
                        style={{ backgroundImage: 'url(/img/pic-demo01.jpg)' }}
                      ></div>
                    </div>
                  </a>
                  <h3 className="aidemo-item__title">リップシンクAI</h3>
                  <p className="aidemo-item__text">
                    入力されたテキストに応じて、デジタルヒューマンが話している動画を自動生成することができます。
                  </p>
                  <Link className="aidemo-item__btn" to={PATH.DEMO_LIPSYNC_AI}>
                    デモのご利用
                  </Link>
                </div>
                <div className="aidemo-item aidemo-item-soon fadein2">
                  <h4 className="aidemo-item__head">
                    AI DEMO<span>02</span>
                  </h4>
                  <div className="aidemo-item__thumb">
                    <div className="play-btn">
                      <div className="play-btn__inner">
                        <img
                          className="play-btn__icon"
                          src={process.env.PUBLIC_URL + '/img/icon-play.png'}
                          alt=""
                        />
                        <span className="play-btn__text">PLAY</span>
                      </div>
                    </div>
                    <div className="aidemo-item__thumb-frame">
                      <div className="aidemo-item__thumb-pic">
                        <span>COMING SOON</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="aidemo-item__title">顔生成AI</h3>
                  <p className="aidemo-item__text">
                    多種多様な外見のタイプからなるデジタルヒューマンの顔画像を自動的に生成することができます。
                  </p>
                  <div className="aidemo-item__btn">デモのご利用</div>
                </div>
                <div className="aidemo-item aidemo-item-soon fadein3">
                  <h4 className="aidemo-item__head">
                    AI DEMO<span>03</span>
                  </h4>
                  <div className="aidemo-item__thumb">
                    <div className="play-btn">
                      <div className="play-btn__inner">
                        <img
                          className="play-btn__icon"
                          src={process.env.PUBLIC_URL + '/img/icon-play.png'}
                          alt=""
                        />
                        <span className="play-btn__text">PLAY</span>
                      </div>
                    </div>
                    <div className="aidemo-item__thumb-frame">
                      <div className="aidemo-item__thumb-pic">
                        <span>COMING SOON</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="aidemo-item__title">フェイススワップAI</h3>
                  <p className="aidemo-item__text">
                    デジタルヒューマンの顔を様々な他の顔に自由に自然かつ同一人物性高くカスタマイズすることができます。
                  </p>
                  <div className="aidemo-item__btn">デモのご利用</div>
                </div>
                <div className="aidemo-item aidemo-item-soon fadein">
                  <h4 className="aidemo-item__head">
                    AI DEMO<span>04</span>
                  </h4>
                  <div className="aidemo-item__thumb">
                    <div className="play-btn">
                      <div className="play-btn__inner">
                        <img
                          className="play-btn__icon"
                          src={process.env.PUBLIC_URL + '/img/icon-play.png'}
                          alt=""
                        />
                        <span className="play-btn__text">PLAY</span>
                      </div>
                    </div>
                    <div className="aidemo-item__thumb-frame">
                      <div className="aidemo-item__thumb-pic">
                        <span>COMING SOON</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="aidemo-item__title">アバターAI</h3>
                  <p className="aidemo-item__text">
                    デジタルヒューマンをアバターとして、ユーザーの動きに同期させて動かすことができます。
                  </p>
                  <div className="aidemo-item__btn">デモのご利用</div>
                </div>
                <div className="aidemo-item aidemo-item-soon fadein2">
                  <h4 className="aidemo-item__head">
                    AI DEMO<span>05</span>
                  </h4>
                  <div className="aidemo-item__thumb">
                    <div className="play-btn">
                      <div className="play-btn__inner">
                        <img
                          className="play-btn__icon"
                          src={process.env.PUBLIC_URL + '/img/icon-play.png'}
                          alt=""
                        />
                        <span className="play-btn__text">PLAY</span>
                      </div>
                    </div>
                    <div className="aidemo-item__thumb-frame">
                      <div className="aidemo-item__thumb-pic">
                        <span>COMING SOON</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="aidemo-item__title">バーチャル試着AI</h3>
                  <p className="aidemo-item__text">
                    人物画像と洋服のアイテム画像から、入力されたアイテムを着用したバーチャル試着画像を合成できます。
                  </p>
                  <div className="aidemo-item__btn">デモのご利用</div>
                </div>
                <div className="aidemo-item aidemo-item-soon fadein3">
                  <h4 className="aidemo-item__head">
                    AI DEMO<span>06</span>
                  </h4>
                  <div className="aidemo-item__thumb">
                    <div className="play-btn">
                      <div className="play-btn__inner">
                        <img
                          className="play-btn__icon"
                          src={process.env.PUBLIC_URL + '/img/icon-play.png'}
                          alt=""
                        />
                        <span className="play-btn__text">PLAY</span>
                      </div>
                    </div>
                    <div className="aidemo-item__thumb-frame">
                      <div className="aidemo-item__thumb-pic">
                        <span>COMING SOON</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="aidemo-item__title">髪試着AI</h3>
                  <p className="aidemo-item__text">
                    ユーザー画像と髪型画像を入力すると、ユーザーの髪型を指定した髪型に変換した画像を生成することができます。
                  </p>
                  <div className="aidemo-item__btn">デモのご利用</div>
                </div>
              </div>
              {/* モーダル枠 */}
              <div className={modalClass()} id="modal-01">
                <a className="modal-overlay" onClick={() => stopPlayVideo()} />
                <div className="modal-window">
                  <div className="modal-content movie">
                    <iframe
                      id="youtube-player"
                      width="560"
                      height="315"
                      src={youtubeSrc}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <a className="close-btn modal-close" onClick={() => stopPlayVideo()}>
                    <div className="close-btn__border"> </div>
                    <div className="close-btn__cross"></div>
                    <svg
                      className="close-btn__circle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g
                        id="長方形_1"
                        data-name="長方形 1"
                        fill="none"
                        stroke="#000"
                        strokeWidth="1"
                      >
                        <rect width="30" height="30" rx="15" stroke="none" />
                        <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Top;
