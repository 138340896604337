import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '../../util/path';
import { useScrollTop } from '../../hook/useScrollTop';
import { useWindowDimensions } from '../../hook/useWindowDimensions';
import { fadein } from '../../util/fadein';
import { Helmet } from 'react-helmet-async';
import Footer from '../../component/Footer';
import Header from '../../component/Header';
import { GTM } from '../../component/GTM';

const Page404: FC = () => {
  const scrollTop = useScrollTop();
  const { width, height } = useWindowDimensions();

  // SP表示時も横幅をPCと同一に保つ
  const viewportMeta = document.getElementById('viewportMeta');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', 'width=1200');
  }

  useEffect(() => {
    fadein(scrollTop, width, height);
  }, [scrollTop, width, height]);

  return (
    <div>
      <GTM />
      <Helmet>
        <title>
          お探しのページが見つかりませんでした｜株式会社データグリッド - AIプラットフォーム
        </title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className="under" id="error">
        <div className="back-dots">
          <Header withLogin={true} num={2} />
          <div className="error-bg" />
          <main>
            <div className="bg-rough">
              <div className="error-page">
                <h1 className="error-page__head fadein">404 NOT FOUND</h1>
                <h2 className="error-page__subhead fadein ">お探しのページは見つかりません。</h2>
                <p className="error-page__text fadein ">
                  一時的にアクセスできないか、
                  <br />
                  移動または削除された可能性があります。
                </p>
                <Link className="login-item__btn fadein " to={PATH.TOP}>
                  TOPへもどる
                </Link>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Page404;
