import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import './index.css';
import { PATH } from '../../util/path';
import { removeError } from '../../state/slices/userinfo';
import { useScrollTop } from '../../hook/useScrollTop';
import { useWindowDimensions } from '../../hook/useWindowDimensions';
import { fadein } from '../../util/fadein';
import { createHistory, HISTORY_ACTION } from '../../state/slices/histories';
import { Helmet } from 'react-helmet-async';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { GTM } from '../../component/GTM';

const Login: FC = () => {
  const [errMsgEmail, setErrMsgEmail] = useState('');
  const [errMsgPassword, setErrMsgPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { prevPathname } = (location.state as { prevPathname: string }) || '';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // SP表示時も横幅をPCと同一に保つ
  const viewportMeta = document.getElementById('viewportMeta');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', 'width=1200');
  }

  const navigateDemo = useCallback(() => {
    if (
      prevPathname?.slice(0, 15) === PATH.DEMO_DOWNLOAD ||
      prevPathname === PATH.DEMO_LIPSYNC_AI
    ) {
      navigate(prevPathname);
    } else {
      navigate(PATH.DEMO);
    }
  }, [navigate, prevPathname]);
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrMsgEmail('');
    setErrMsgPassword('');
    // サインイン
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        dispatch(removeError());
      })
      .catch((error) => {
        console.log(error);
        switch (error.code) {
          case 'auth/invalid-email':
          case 'auth/user-not-found':
            setErrMsgEmail('メールアドレスが間違っています。');
            break;
          case 'auth/wrong-password':
            setErrMsgPassword('パスワードが間違っています。');
            break;
          default:
            break;
        }
      });
  };
  useEffect(() => {
    let unmounted = false;
    // ログイン済みの場合はデモページへ
    onAuthStateChanged(auth, async (user) => {
      if (!unmounted && user) {
        try {
          await user?.reload();
          await createHistory(user.uid, HISTORY_ACTION.LOGIN);
        } catch (e) {
          console.log(e);
        }
        navigateDemo();
      }
    });
    // cleanup
    return () => {
      unmounted = true;
    };
  }, [navigateDemo]);

  // fadein
  const scrollTop = useScrollTop();
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    fadein(scrollTop, width, height);
  }, [scrollTop, width, height]);

  return (
    <div className="under" id="login">
      <GTM />
      <Helmet>
        <title>ログイン｜株式会社データグリッド - AIプラットフォーム</title>
        <meta
          name="description"
          content="AIデモンストレーションのご利用にはログインが必要です。アカウントをお持ちの方は、このページからログインしてください。"
        />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className="back-dots">
        <Header withLogin={false} />
        <main>
          <div className="bg-rough">
            <div className="login-page">
              <div className="top-heading fadein">
                <h1 className="top-heading__title top-heading__title-ai">
                  <span>ログイン</span>LOGIN
                  <p>AIデモンストレーションのご利用にはログインが必要です</p>
                </h1>
              </div>
              <div className="login-shelf">
                <div className="login-item login-item-left fadein">
                  <h2 className="login-item__heading">無料アカウント申請</h2>
                  <div className="login-item__contents">
                    <p className="login-item__text">
                      当社よりアカウントを付与させていただいた後には、
                      <br />
                      すべてのAIモジュールをお試しいただけます。
                    </p>
                  </div>
                  <a
                    className="login-item__btn"
                    href="https://forms.gle/zBsnfteKsnkdFmKY8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    アカウントを申請する
                  </a>
                </div>
                <div className="login-item login-item-right fadein2">
                  <h2 className="login-item__heading">ログイン</h2>
                  <div className="login-item__contents">
                    <div className="login-item__contents__inner">
                      <p className="login-item__text">
                        アカウントをお持ちの方はこちらからログインしてください。
                      </p>
                      <div className="loginform-shelf">
                        <h3 className="loginform-title">
                          <label htmlFor="email">メールアドレス</label>
                          <span className="error-text">{errMsgEmail}</span>
                        </h3>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          defaultValue={email}
                          onChange={(event) => setEmail(event.target.value)}
                          placeholder="例）sample@xxxxx.co.jp"
                        />
                      </div>
                      <div className="loginform-shelf">
                        <h3 className="loginform-title">
                          <label htmlFor="password">パスワード</label>
                          <span className="error-text">{errMsgPassword}</span>
                        </h3>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          defaultValue={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </div>
                      <p className="login-item-forgot">
                        メールアドレス、パスワードを忘れた方は
                        <a
                          href="https://forms.gle/4XNKrMhvKQkfp7Zu8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          こちら
                        </a>
                      </p>
                    </div>
                  </div>
                  <a className="login-item__btn" href="" onClick={handleSubmit}>
                    ログイン
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
