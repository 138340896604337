import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import UUID from 'uuidjs';

export interface Histories {
  histories: Array<History>;
  error?: SerializedError;
}

const initialState: Histories = {
  histories: [],
  error: undefined,
};
export const HISTORY_ACTION = {
  LOGIN: 'LOGIN',
  PLAY_SAMPLE: 'PLAY_SAMPLE',
  REQUEST_DEMO: 'REQUEST_DEMO',
  PLAY_TEXT: 'PLAY_TEXT',
  DL_DEMO: 'DL_DEMO',
  REQUEST_APIKEY: 'REQUEST_APIKEY',
} as const;
export type HISTORY_ACTION = typeof HISTORY_ACTION[keyof typeof HISTORY_ACTION];

export interface History {
  historyId: string;
  uid: string;
  timestamp?: number;
  action?: HISTORY_ACTION;
  attributes?: Record<string, string>;
}

export const createHistory = (
  uid: string,
  action: HISTORY_ACTION,
  attributes?: Record<string, string>
): Promise<void> => {
  const history = {
    historyId: UUID.genV4().toString(),
    uid: uid,
    timestamp: Math.floor(Date.now() / 1000),
    action: action,
  } as History;
  if (attributes) {
    history['attributes'] = attributes;
  }
  return new Promise<void>((resolve, reject) => {
    const createDoc = doc(db, 'histories', history.historyId);
    setDoc(createDoc, history)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const historiesSlice = createSlice({
  name: 'histories',
  initialState,
  reducers: {
    removeError: (state) => {
      state.error = undefined;
    },
  },
});

export const { removeError } = historiesSlice.actions;
