import { createSelector } from 'reselect';
import { RootState } from '../store';

// userinfo情報を取得するSelector
export const userinfoSelector = (state: RootState) => state.userinfo;

/**
 * ユーザIDを取得する
 * @returns name
 */
export const userIdSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.userId;
});

/**
 * Emailを取得する
 * @returns name
 */
export const emailSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.email;
});

/**
 * 名前を取得する
 * @returns name
 */
export const nameSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.name;
});

/**
 * 企業名・団体名を取得する
 * @returns name
 */
export const organizationSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.organization;
});

/**
 * 部署名を取得する
 * @returns name
 */
export const organizationUnitSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.organizationUnit;
});

/**
 * 役職名を取得する
 * @returns name
 */
export const positionSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.position;
});

/**
 * errorを取得する
 * @returns error
 */
export const errorSelector = createSelector(userinfoSelector, (userinfo) => {
  return userinfo.error;
});
