import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '../../util/path';
import { useScrollTop } from '../../hook/useScrollTop';
import { useWindowDimensions } from '../../hook/useWindowDimensions';
import { fadein } from '../../util/fadein';
import Menu, { ShowMenuHandle } from '../../component/Menu';
import { useSelector } from '../../state/store';
import { errorSelector } from '../../state/selectors/userinfo';
import { openModal, closeModal } from '../../util/utils';
import { Helmet } from 'react-helmet-async';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { GTM } from '../../component/GTM';

const Demo: FC = () => {
  const navigate = useNavigate();
  const MenuRef = useRef<ShowMenuHandle>(null);
  const error = useSelector(errorSelector);
  const DEFAULT_YOUTUBE_URL =
    'https://www.youtube-nocookie.com/embed/FOpCoiNhTK8?autoplay=1&mute=1&playsinline=1&loop=1&playlist=FOpCoiNhTK8';

  const onClickMenu = (type: string) => {
    MenuRef.current?.showMenu(type);
  };
  const [youtubeSrc, setYoutubeSrc] = useState<string>(DEFAULT_YOUTUBE_URL);
  const [showModal, setShowModal] = useState<boolean>(false);
  const modalClass = () => {
    return showModal ? 'modal-wrapper modal-show' : 'modal-wrapper modal-hide';
  };
  const stopPlayVideo = () => {
    closeModal();
    setYoutubeSrc('');
    setShowModal(false);
  };

  // SP表示時も横幅をPCと同一に保つ
  const viewportMeta = document.getElementById('viewportMeta');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', 'width=1200');
  }

  useEffect(() => {
    if (youtubeSrc === '') {
      setYoutubeSrc(DEFAULT_YOUTUBE_URL);
    }
  }, [youtubeSrc]);

  useEffect(() => {
    if (error) {
      navigate(PATH.LOGIN, { state: { prevPathname: location.pathname }, replace: true });
    }
  }, [error, navigate]);

  // fadein
  const scrollTop = useScrollTop();
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    fadein(scrollTop, width, height);
  }, [scrollTop, width, height]);

  return (
    <div>
      <GTM />
      <Helmet>
        <title>AIデモ｜株式会社データグリッド - AIプラットフォーム</title>
        <meta
          name="description"
          content="当プラットフォームでは、データグリッドの持つ最先端のデジタルヒューマンAI技術を無料で体験いただけます。顔や服装、姿勢や声も思いのまま。コンテンツ制作を変える多様な技術の商用利用のご相談も受け付けています。"
        />
      </Helmet>
      <div className="under" id="mypage">
        <div className="back-dots">
          <Header withLogin={false}>
            <div className="icon-shelf">
              <div className="icon-shelf__box" onClick={() => onClickMenu('log')}>
                {' '}
                <span className="icon-shelf__text">履歴</span>
                <img src={process.env.PUBLIC_URL + '/img/icon-history_white.png'} alt="" />
              </div>
              <div className="icon-shelf__box" onClick={() => onClickMenu('account')}>
                {' '}
                <span className="icon-shelf__text">マイページ</span>
                <img src={process.env.PUBLIC_URL + '/img/icon-human_white.png'} alt="" />
              </div>
              <div className="icon-shelf__box" onClick={() => onClickMenu('config')}>
                {' '}
                <span className="icon-shelf__text">設定</span>
                <img src={process.env.PUBLIC_URL + '/img/icon-gear_white.png'} alt="" />
              </div>
            </div>
          </Header>
          <Menu ref={MenuRef} />
          <main>
            <div className="bg-rough">
              <div className="mypage-contens">
                <div className="top-heading">
                  <h1 className="top-heading__title top-heading__title-ai fadein">
                    <span>AI デモンストレーション</span>AI DEMO
                    <p>※スマートフォン、タブレットではご利用になれません。PCでご利用ください。</p>
                  </h1>
                  <div className="white-line fadein"></div>
                </div>
                <div className="aidemo-shelf fadein">
                  <div className="aidemo-item fadein">
                    <h4 className="aidemo-item__head">
                      AI DEMO<span>01</span>
                    </h4>
                    <a
                      className="aidemo-item__thumb"
                      onClick={() => {
                        openModal();
                        setShowModal(true);
                      }}
                    >
                      <div className="play-btn">
                        <div className="play-btn__inner">
                          <span className="play-btn__text">PLAY</span>
                          <img
                            className="play-btn__icon"
                            src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="aidemo-item__thumb-frame">
                        <div
                          className="aidemo-item__thumb-pic"
                          style={{ backgroundImage: 'url(/img/pic-demo01.jpg)' }}
                        ></div>
                      </div>
                    </a>
                    <h3 className="aidemo-item__title">リップシンクAI</h3>
                    <p className="aidemo-item__text">
                      入力されたテキストに応じて、デジタルヒューマンが話している動画を自動生成することができます。
                    </p>
                    <Link className="aidemo-item__btn" to={PATH.DEMO_LIPSYNC_AI}>
                      MORE
                    </Link>
                  </div>
                  <div className="aidemo-item aidemo-item-soon fadein2">
                    <h4 className="aidemo-item__head">
                      AI DEMO<span>02</span>
                    </h4>
                    <div className="aidemo-item__thumb">
                      <div className="play-btn">
                        <div className="play-btn__inner">
                          <span className="play-btn__text">PLAY</span>
                          <img
                            className="play-btn__icon"
                            src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="aidemo-item__thumb-frame">
                        <div className="aidemo-item__thumb-pic">
                          <span>COMING SOON</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="aidemo-item__title">顔生成AI</h3>
                    <p className="aidemo-item__text">
                      多種多様な外見のタイプからなるデジタルヒューマンの顔画像を自動的に生成することができます。
                    </p>
                    <div className="aidemo-item__btn">MORE</div>
                  </div>
                  <div className="aidemo-item aidemo-item-soon fadein3">
                    <h4 className="aidemo-item__head">
                      AI DEMO<span>03</span>
                    </h4>
                    <div className="aidemo-item__thumb">
                      <div className="play-btn">
                        <div className="play-btn__inner">
                          <span className="play-btn__text">PLAY</span>
                          <img
                            className="play-btn__icon"
                            src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="aidemo-item__thumb-frame">
                        <div className="aidemo-item__thumb-pic">
                          <span>COMING SOON</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="aidemo-item__title">フェイススワップAI</h3>
                    <p className="aidemo-item__text">
                      デジタルヒューマンの顔を様々な他の顔に自由に自然かつ同一人物性高くカスタマイズすることができます。
                    </p>
                    <div className="aidemo-item__btn">MORE</div>
                  </div>
                  <div className="aidemo-item aidemo-item-soon fadein">
                    <h4 className="aidemo-item__head">
                      AI DEMO<span>04</span>
                    </h4>
                    <div className="aidemo-item__thumb">
                      <div className="play-btn">
                        <div className="play-btn__inner">
                          <span className="play-btn__text">PLAY</span>
                          <img
                            className="play-btn__icon"
                            src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="aidemo-item__thumb-frame">
                        <div className="aidemo-item__thumb-pic">
                          <span>COMING SOON</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="aidemo-item__title">アバターAI</h3>
                    <p className="aidemo-item__text">
                      デジタルヒューマンをアバターとして、ユーザーの動きに同期させて動かすことができます。
                    </p>
                    <div className="aidemo-item__btn">MORE</div>
                  </div>
                  <div className="aidemo-item aidemo-item-soon fadein2">
                    <h4 className="aidemo-item__head">
                      AI DEMO<span>05</span>
                    </h4>
                    <div className="aidemo-item__thumb">
                      <div className="play-btn">
                        <div className="play-btn__inner">
                          <span className="play-btn__text">PLAY</span>
                          <img
                            className="play-btn__icon"
                            src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="aidemo-item__thumb-frame">
                        <div className="aidemo-item__thumb-pic">
                          <span>COMING SOON</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="aidemo-item__title">バーチャル試着AI</h3>
                    <p className="aidemo-item__text">
                      人物画像と洋服のアイテム画像から、入力されたアイテムを着用したバーチャル試着画像を合成できます。
                    </p>
                    <div className="aidemo-item__btn">MORE</div>
                  </div>
                  <div className="aidemo-item aidemo-item-soon fadein3">
                    <h4 className="aidemo-item__head">
                      AI DEMO<span>06</span>
                    </h4>
                    <div className="aidemo-item__thumb">
                      <div className="play-btn">
                        <div className="play-btn__inner">
                          <span className="play-btn__text">PLAY</span>
                          <img
                            className="play-btn__icon"
                            src={process.env.PUBLIC_URL + '/img/icon-play-arrow.png'}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="aidemo-item__thumb-frame">
                        <div className="aidemo-item__thumb-pic">
                          <span>COMING SOON</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="aidemo-item__title">髪試着AI</h3>
                    <p className="aidemo-item__text">
                      ユーザー画像と髪型画像を入力すると、ユーザーの髪型を指定した髪型に変換した画像を生成することができます。
                    </p>
                    <div className="aidemo-item__btn">MORE</div>
                  </div>
                </div>
                {/* モーダル枠 */}
                <div className={modalClass()} id="modal-01">
                  <a className="modal-overlay" onClick={() => stopPlayVideo()} />
                  <div className="modal-window">
                    <div className="modal-content movie">
                      <iframe
                        id="youtube-player"
                        width="560"
                        height="315"
                        src={youtubeSrc}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                    <a className="close-btn modal-close" onClick={() => stopPlayVideo()}>
                      <div className="close-btn__border"> </div>
                      <div className="close-btn__cross"></div>
                      <svg
                        className="close-btn__circle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g
                          id="長方形_1"
                          data-name="長方形 1"
                          fill="none"
                          stroke="#000"
                          strokeWidth="1"
                        >
                          <rect width="30" height="30" rx="15" stroke="none" />
                          <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Demo;
