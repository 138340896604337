import { useCallback, useEffect, VFC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Top from './pages/top';
import Login from './pages/login';
import Demo from './pages/demo';
import LipsyncAi from './pages/demo/lipsync-ai';
import Download from './pages/demo/download';
import Page404 from './pages/page404/404';
import Redirect from './pages/page404/redirect';
import { PATH } from './util/path';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { getUserinfo } from './state/slices/userinfo';
import { getApiHistories } from './state/slices/apiHistories';
import ScrollToTop from './ScrollToTop';

const App: VFC = () => {
  const dispatch = useDispatch();

  const userinfo = useCallback(
    (user) => {
      dispatch(getUserinfo(user));
    },
    [dispatch]
  );
  const apiHistory = useCallback(
    (user) => {
      dispatch(getApiHistories(user));
    },
    [dispatch]
  );

  useEffect(() => {
    let unmounted = false;
    // ログイン状態を監視
    onAuthStateChanged(auth, async (user) => {
      if (unmounted) {
        return;
      }
      userinfo(user);
      apiHistory(user?.uid);
    });
    // cleanup
    return () => {
      unmounted = true;
    };
  }, [userinfo, apiHistory]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={PATH.TOP} element={<Top />} />
        <Route path={PATH.LOGIN} element={<Login />} />
        <Route path={PATH.DEMO} element={<Demo />} />
        <Route path={PATH.DEMO_LIPSYNC_AI} element={<LipsyncAi />} />
        <Route path={PATH.DEMO_DOWNLOAD + ':uuid'} element={<Download />} />
        <Route path={PATH.PAGE404} element={<Page404 />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
