import React, { FC } from 'react';
import { useSelector } from '../state/store';
import { apiHistoriesAllSelector } from '../state/selectors/apiHistories';
import { API_STATUS } from '../state/slices/apiHistories';
import { DEFAULT_DIGITAL_HUMAN_IMG, DIGITAL_HUMANS } from '../pages/demo/lipsync-ai';
const MenuLog: FC = () => {
  const apiHistories = useSelector(apiHistoriesAllSelector);
  const logList = [];
  if (apiHistories) {
    for (const apiHistory of apiHistories) {
      let generatedDate = '----.--.--';
      let generatedTime = '----:--';
      if (apiHistory.status === API_STATUS.IN_PROGRESS) {
        generatedDate = '生成中';
        generatedTime = '';
      }
      if (apiHistory.status === API_STATUS.FAILED) {
        generatedDate = '生成失敗';
        generatedTime = '';
      }
      let backgroundImage = DEFAULT_DIGITAL_HUMAN_IMG;
      if (apiHistory.voice) {
        for (const digitalHuman of DIGITAL_HUMANS) {
          if (digitalHuman.value === apiHistory.voice) {
            backgroundImage = digitalHuman.img;
          }
        }
      }

      if (apiHistory.request_timestamp) {
        const date = new Date(apiHistory.request_timestamp * 1000);
        const yyyy = `${date.getFullYear()}`;
        const MM = `0${date.getMonth() + 1}`.slice(-2);
        const dd = `0${date.getDate()}`.slice(-2);
        const HH = `0${date.getHours()}`.slice(-2);
        const mm = `0${date.getMinutes()}`.slice(-2);

        generatedDate = `${yyyy}-${MM}-${dd}`;
        generatedTime = `${HH}:${mm}`;
      }

      logList.push(
        <li className="log-box" key={apiHistory.jobId}>
          <div
            className={`log-box__thumb ${
              apiHistory.status === API_STATUS.IN_PROGRESS && '-generating'
            }`}
          >
            <div className="log-box__thumb-frame">
              <div className="log-box__thumb-pic" style={{ backgroundImage: backgroundImage }} />
            </div>
          </div>
          <div className="log-box__contents">
            <div className="log-box__contents-top">
              <p className="log-box__subtitle">
                AI DEMO<span className="log-box__subtitle-number log-number-opacity">01</span>
              </p>
              <p className="log-box__title">リップシンクAI</p>
            </div>
            <div className="log-box__contents-bottom">
              <p className="log-box__date-head">作成日時</p>
              <p className="log-box__date">{generatedDate}</p>
              <p className="log-box__time">{generatedTime}</p>
            </div>
          </div>
        </li>
      );
    }
  }
  return (
    <div className="menu-contents menu-contents-log wf-active delay">
      <p className="menu-contents__head">デモご利用履歴</p>
      <ul className="log-shelf">{logList}</ul>
    </div>
  );
};
export default MenuLog;
