export const openModal = () => {
  const body = document.getElementsByTagName('body')[0];
  if (!body.classList.contains('fixed')) {
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;
    const bodyStyle = window.getComputedStyle(body);
    // 横幅 1920px より大きいサイズで見ていた場合に、fixed を付与すると
    // window が自動で設定した margin が消えてしまうため、明示的に marginLeft を設定する
    if (bodyStyle.marginLeft) {
      body.style.marginLeft = bodyStyle.marginLeft
    }
    body.classList.add('fixed');
    body.style.top = '-' + scrollY.toString() + 'px';
    body.style.left = '-' + scrollX.toString() + 'px';
  }
};
export const closeModal = () => {
  const body = document.getElementsByTagName('body')[0];
  if (body.classList.contains('fixed')) {
    body.classList.remove('fixed');
    const scrollY = Math.abs(parseInt(body.style.top.replace('px', '')));
    const scrollX = Math.abs(parseInt(body.style.left.replace('px', '')));
    window.scroll(scrollX, scrollY);
    body.style.margin = '0 auto';
    body.style.top = 'unset';
    body.style.left = 'unset';
  }
  setTimeout(() => history.replaceState('', '', location.pathname), 0);
};
